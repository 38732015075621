export enum WorkflowType {
  LE_VLEI_PREREQ = 'LE_VLEI_PREREQ',
  OOR_VLEI_PREREQ = 'OOR_VLEI_PREREQ',
  ECR_VLEI_PREREQ = 'ECR_VLEI_PREREQ'
}

export enum WorkflowInstanceStatus {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}
