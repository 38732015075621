import { Task } from '@/models/workflow/task'
import { TaskActor } from '@/models/workflow/task-actor'
import { Workflow } from '@/models/workflow/workflow'
import OorWorkflowTask from '@/types/oor-workflow-task'
import { WorkflowType } from '@/types/workflow'
import { WorkflowInstanceDto } from '@/api/origin-workflow-svc'

export class OorWorkflow extends Workflow {
  constructor(data: WorkflowInstanceDto) {
    super(data, WorkflowType.OOR_VLEI_PREREQ)
  }

  protected formatTasks(): Task[] {
    return this.data.taskTypes
      .filter(
        (taskType) =>
          OorWorkflowTask[taskType.taskType as keyof typeof OorWorkflowTask] !==
          undefined
      )
      .map((taskType) => {
        const actors = taskType.taskActors.map(
          (actor) =>
            new TaskActor(
              actor.workflowInstanceAssignedTaskId,
              actor.workflowInstanceId,
              actor.userId,
              actor.userName,
              actor.taskType,
              // todo speak with BE team to update swagger docs
              // @ts-ignore
              actor.status
            )
        )

        return new Task(taskType.taskType, actors)
      })
  }
}
