import React, { ReactNode } from 'react'

export type BadgeColor =
  | 'default'
  | 'blue'
  | 'red'
  | 'green'
  | 'yellow'
  | 'orange'
  | 'indigo'
  | 'purple'
  | 'pink'

type IPillType = 'default' | 'bordered' | 'pill'

type IPillSize = 'small' | 'medium'

export interface IPillProps {
  label: ReactNode
  color?: BadgeColor
  type?: IPillType
  size?: IPillSize
  className?: string
}

const Badges = ({
  label,
  color = 'default', // Default type is set to 'pending'
  type = 'default', // Default type is set to 'pending'
  size = 'small',
  className,
  ...rest
}: IPillProps) => {
  let bgColor = ''
  let darkBgColor = ''
  let textSize = ''
  const common = 'font-medium me-2 px-2.5 py-0.5 text-start w-max'
  // Assign round size based on type
  const rounded = type == 'pill' ? 'rounded-full' : 'rounded'
  // Assign textSize based on size prop
  textSize = size === 'small' ? 'text-xs' : 'text-sm'
  // Assign colors based on colors prop
  bgColor =
    color === 'default'
      ? 'bg-gray-100 text-gray-800'
      : color === 'blue'
      ? 'bg-blue-100 text-blue-800'
      : color === 'red'
      ? 'bg-red-100 text-red-800'
      : color === 'green'
      ? 'bg-green-100 text-green-800'
      : color === 'orange'
      ? 'bg-orange-100 text-orange-800'
      : color === 'yellow'
      ? 'bg-yellow-100 text-yellow-800'
      : color === 'indigo'
      ? 'bg-indigo-100 text-indigo-800'
      : color === 'purple'
      ? 'bg-purple-100 text-purple-800'
      : 'bg-pink-100 text-pink-800'

  // Assign darkBGcolors based on colors prop
  darkBgColor =
    color === 'default'
      ? type == 'bordered'
        ? 'dark:bg-gray-700 dark:text-gray-400 border border-neutralsAdditional400'
        : 'dark:bg-gray-700 dark:text-gray-300'
      : color === 'blue'
      ? type == 'bordered'
        ? 'dark:bg-gray-700 dark:text-blue-400 border border-blue-400'
        : 'dark:bg-blue-900 dark:text-blue-300'
      : color === 'red'
      ? type == 'bordered'
        ? 'dark:bg-gray-700 dark:text-red-400 border border-red-400'
        : 'dark:bg-red-900 dark:text-red-300'
      : color === 'green'
      ? type == 'bordered'
        ? 'dark:bg-gray-700 dark:text-green-400 border border-green-400'
        : 'dark:bg-green-900 dark:text-green-300'
      : color === 'orange'
      ? type == 'bordered'
        ? 'dark:bg-gray-700 dark:text-orange-300 border border-orange-300'
        : 'dark:bg-orange-900 dark:text-orange-300'
      : color === 'yellow'
      ? type == 'bordered'
        ? 'dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300'
        : 'dark:bg-yellow-900 dark:text-yellow-300'
      : color === 'indigo'
      ? type == 'bordered'
        ? 'dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400'
        : 'dark:bg-indigo-900 dark:text-indigo-300'
      : color === 'purple'
      ? type == 'bordered'
        ? 'dark:bg-gray-700 dark:text-purple-400 border border-purple-400'
        : 'dark:bg-purple-900 dark:text-purple-300'
      : type == 'bordered'
      ? 'dark:bg-gray-700 dark:text-pink-400 border border-pink-400'
      : 'dark:bg-pink-900 dark:text-pink-300'

  const pillClasses = `
      ${bgColor} ${darkBgColor}
      ${textSize} ${common} ${rounded}
      `
  return (
    <div className={pillClasses} {...rest}>
      {label}
    </div>
  )
}

export default Badges
