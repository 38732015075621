import { PlusCircle } from 'phosphor-react'

interface IProps {
  size?: number
  color?: string
  weight?: 'thin' | 'light' | 'bold' | 'fill' | 'duotone'
}

const PlusIcon = ({ size = 16, color = '#000', weight, ...props }: IProps) => {
  return <PlusCircle size={size} color={color} weight={weight} {...props} />
}

export default PlusIcon
