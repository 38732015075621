import AccordionTable from '@/components/base/AccordionTable'
import { Button } from '@/components/base/Button'
import { ProgressBar } from '@/components/base/ProgressBar'
import Spinner from '@/components/base/Spinner'
import PlusIcon from '@/components/icons/PlusIcon'
import constants from '@/config/constants'
import { getWorkflows } from '@/services/workflow'
import useStore from '@/state/store'
import { taskTypeToMenuItemMap } from '@/utils/vlei'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import tailwindConfig from 'tailwind.config'
import InvitationResponseToast from '@/components/invitation/invitationResponseToast'
import DropdownComponent from '@/components/base/Dropdown'
import { TaskStatusIcon, TaskStatusTag } from '@/features/my-work'
import TaskStatus from '@/enums/taskStatus'
import OorWorkflowTask from '@/types/oor-workflow-task'
import { OorWorkflow } from '@/models/oor-vlei-workflow'
import { oorTaskPageMapping, useOorWorkflowStore } from '@/features/oor'
import { EcrWorkflow } from '@/models/ecr-vlei-workflow'
import { EcrTaskPageMapping, useEcrWorkflowStore } from '@/features/ecr'
import EcrWorkflowTask from '@/types/ecr-workflow-task'
import { format, formatDistanceToNow } from 'date-fns'
import { WorkflowInstanceDto } from '@/api/origin-workflow-svc'
import Pill from '@/components/base/Pill'
import { WorkflowFilter } from '@/features/my-work/components/workflow-filter/WorkflowFilter'
import useKeriaAgentSync from '@/hooks/useKeriaAgentSync'
import {
  captureErrorWithScope,
  SentryContextEnum
} from '@/shared/error-capture'

const prepareWorkflowData = (workflow: WorkflowInstanceDto) => {
  const isWorkflowCanceled = workflow.status === 'CANCELED'

  //TODO: refractor this.

  if (
    workflow.workflowModelId?.toLocaleLowerCase() ==
    'create-le-vlei-prerequisites'
  ) {
    return workflow.taskTypes.map((taskType) => {
      // Check if taskTypes and taskActors exist and are not empty
      if (
        taskType.taskActors &&
        taskType.taskActors.length > 0 &&
        taskType.taskActors[0].status
      ) {
        const { label } = taskTypeToMenuItemMap[taskType.taskType]
        const taskStatus =
          TaskStatus[taskType.taskActors[0]?.status as keyof typeof TaskStatus]
        return {
          nodeName: (
            <div className="flex gap-2">
              {
                <TaskStatusIcon
                  status={isWorkflowCanceled ? TaskStatus.ASSIGNED : taskStatus}
                ></TaskStatusIcon>
              }
              <span
                className={`text-base ${
                  !isWorkflowCanceled &&
                  taskType.taskActors[0].status ===
                    constants.WORKFLOW_NODE_STATUS.COMPLETE
                    ? 'line-through'
                    : ''
                }`}
              >
                {<FormattedMessage id={label} />}
              </span>
            </div>
          ),
          assignee: <>{taskType.taskActors[0].userName}</>,
          nodeStatus: isWorkflowCanceled ? null : (
            <>
              <TaskStatusTag status={taskStatus}></TaskStatusTag>
            </>
          )
        }
      } else {
        return {
          nodeName: 'No data', // Provide a fallback value
          assignee: null, // Provide a fallback value
          nodeStatus: null // Provide a fallback value
        }
      }
    })
  } else if (
    workflow.workflowModelId?.toLocaleLowerCase() ==
    'create-oor-vlei-prerequisites'
  ) {
    const oorWorkflow = new OorWorkflow(workflow)
    return oorTaskPageMapping
      .filter((item) => item.isPublic === true)
      .map((menuItem) => {
        const task = oorWorkflow?.findTaskByType(
          OorWorkflowTask[menuItem.taskType]
        )

        if (task?.hasAssignedActors() === true) {
          const taskRow = {
            nodeName: menuItem.label,
            assignee: task.getActorNamesAsCommaSeparated(),
            nodeStatus: task.calculateTaskStatus(),
            href: `${menuItem.href}?workflow_id=${oorWorkflow.getId()}`,
            actions: null
          }

          return {
            nodeName: (
              <div className="flex gap-2">
                {
                  <TaskStatusIcon
                    status={
                      isWorkflowCanceled
                        ? TaskStatus.ASSIGNED
                        : taskRow.nodeStatus
                    }
                  ></TaskStatusIcon>
                }
                <span
                  className={`text-base ${
                    !isWorkflowCanceled &&
                    taskRow.nodeStatus === TaskStatus.COMPLETE
                      ? 'line-through'
                      : ''
                  }`}
                >
                  <FormattedMessage id={taskRow.nodeName} />
                </span>
              </div>
            ),
            assignee: <>{taskRow.assignee}</>,
            nodeStatus: isWorkflowCanceled ? null : (
              <>
                <TaskStatusTag status={taskRow.nodeStatus}></TaskStatusTag>
              </>
            )
          }
        } else {
          return {
            nodeName: 'No data', // Provide a fallback value
            assignee: null, // Provide a fallback value
            nodeStatus: null // Provide a fallback value
          }
        }
      })
  } else if (
    workflow.workflowModelId?.toLocaleLowerCase() ==
    'create-ecr-vlei-prerequisites'
  ) {
    const ecrWorkflow = new EcrWorkflow(workflow)
    return EcrTaskPageMapping.filter((item) => item.isPublic === true).map(
      (menuItem) => {
        const task = ecrWorkflow?.findTaskByType(
          EcrWorkflowTask[menuItem.taskType]
        )

        if (task?.hasAssignedActors() === true) {
          const taskRow = {
            nodeName: menuItem.label,
            assignee: task.getActorNamesAsCommaSeparated(),
            nodeStatus: task.calculateTaskStatus(),
            href: `${menuItem.href}?workflow_id=${ecrWorkflow.getId()}`,
            actions: null
          }

          return {
            nodeName: (
              <div className="flex gap-2">
                {
                  <TaskStatusIcon
                    status={
                      isWorkflowCanceled
                        ? TaskStatus.ASSIGNED
                        : taskRow.nodeStatus
                    }
                  ></TaskStatusIcon>
                }
                <span
                  className={`text-base ${
                    !isWorkflowCanceled &&
                    taskRow.nodeStatus === TaskStatus.COMPLETE
                      ? 'line-through'
                      : ''
                  }`}
                >
                  <FormattedMessage id={taskRow.nodeName} />
                </span>
              </div>
            ),
            assignee: <>{taskRow.assignee}</>,
            nodeStatus: isWorkflowCanceled ? null : (
              <>
                <TaskStatusTag status={taskRow.nodeStatus}></TaskStatusTag>
              </>
            )
          }
        } else {
          return {
            nodeName: 'No data', // Provide a fallback value
            assignee: null, // Provide a fallback value
            nodeStatus: null // Provide a fallback value
          }
        }
      }
    )
  }
}

const header = (workflow: WorkflowInstanceDto) => {
  const completedSteps = workflow.taskTypes.flatMap((taskType) =>
    taskType.taskActors.filter(
      (actor) => actor.status === constants.WORKFLOW_NODE_STATUS.COMPLETE
    )
  ).length
  let progress = 0
  if (
    workflow.workflowModelId?.toLocaleLowerCase() ==
    'create-oor-vlei-prerequisites'
  ) {
    const oorWorkflow = new OorWorkflow(workflow)
    progress = oorWorkflow.getTaskCompletionStats().percentage
  } else if (
    workflow.workflowModelId?.toLocaleLowerCase() ==
    'create-ecr-vlei-prerequisites'
  ) {
    const ecrWorkflow = new EcrWorkflow(workflow)
    progress = ecrWorkflow.getTaskCompletionStats().percentage
  } else progress = (completedSteps / constants.WORKFLOW_NODE_COUNT) * 100
  const date = new Date(workflow.startedAt)
  const isWorkflowCanceled = workflow.status === 'CANCELED'

  return (
    <>
      <div className="grid grid-cols-2">
        <div>
          <span className="mr-2 text-base font-semibold col-span-auto">
            {Boolean(workflow.name) ? (
              <>{workflow.name}</>
            ) : (
              <FormattedMessage id={'workflow.name.unknown'} />
            )}
          </span>
          {isWorkflowCanceled ? (
            <Pill
              label={<FormattedMessage id="workflow.canceled" />}
              size="small"
              type="canceled"
            />
          ) : null}
        </div>
        {isWorkflowCanceled ? null : (
          <span className="font-medium col-span-auto text-royalBlue text-end">
            <b className="text-black">
              <FormattedMessage id={'workflow.started_at'} />
            </b>
            {`${format(date, 'hh:mm a')} • ${format(
              date,
              'EEEE, MMMM d, yyyy'
            )} `}
            <b className="w-auto text-[11px] text-neutral-600">
              {`(${formatDistanceToNow(date)})`}
            </b>
          </span>
        )}
      </div>
      {isWorkflowCanceled ? null : (
        <span className="w-full mt-2">
          <ProgressBar mode="primary" progress={progress} size="medium" />
        </span>
      )}
    </>
  )
}

const MyWork = () => {
  const { formatMessage } = useIntl()
  const [workflows, setWorkflows] = useState<WorkflowInstanceDto[]>([])
  const [loading, setLoading] = useState(true)
  const router = useRouter()
  const {
    setWorkflowInstanceID,
    resetVleiState,
    authentication: { userInfo },
    vlei: { workflowInstanceId }
  } = useStore()
  const { setWorkflow: setWorkflowOor } = useOorWorkflowStore()
  const { setWorkflow: setWorkflowEcr } = useEcrWorkflowStore()
  const [workflowFilters, setWorkflowFilters] = useState<WorkflowFilter[]>([
    {
      label: 'Active',
      isSelected: false,
      value: 'STARTED'
    },
    {
      label: 'Completed',
      isSelected: false,
      value: 'COMPLETED'
    },
    {
      label: 'Canceled',
      isSelected: false,
      value: 'CANCELED'
    }
  ])

  const orgId = userInfo.orgs?.[0]?.id
  useKeriaAgentSync()

  useEffect(() => {
    setWorkflowOor(null)
    setWorkflowEcr(null)
    const fetchMyWorkflows = async () => {
      try {
        setLoading(true)
        let workflows = []
        // TODO:KS Update the API to accept an array of statuses instead of a single status
        // Since we don't have an API which accepts an array,
        // we are making multiple calls in parallel
        // and then concatenating the results
        if (workflowFilters.some((filter) => filter.isSelected)) {
          const selectedFilters = workflowFilters
            .filter((filter) => filter.isSelected)
            .map((filter) => filter.value)
          // Existing type definition is wrong, so when that is updated
          // we will see the error here
          const workflowByStatuses: Array<Promise<WorkflowInstanceDto[]>> = []
          selectedFilters.forEach((status) => {
            workflowByStatuses.push(getWorkflows(status))
          })
          let resolvedWorkflows = await Promise.all(workflowByStatuses)
          // remove empty responses
          resolvedWorkflows = resolvedWorkflows.filter((workflows) =>
            Boolean(workflows)
          )
          workflows = [].concat(...resolvedWorkflows)
        } else {
          // TODO Fix the type definition for getWorkflows API
          // @ts-ignore type definition is wrong for getWorkflows API
          workflows = await getWorkflows()
        }
        // todo speak with BE team to update swagger docs
        // @ts-ignore
        const filterWorkflowByOrg = workflows.filter((workflow) => {
          // let found = false
          // workflow.taskTypes.forEach((taskType) => {
          //   taskType.taskActors.forEach((taskActor) => {
          //     found = taskActor.userId == userInfo.id
          //   })
          // })

          const found = workflow.taskTypes.some((taskType) =>
            taskType.taskActors.some(
              (taskActor) => taskActor.userId == userInfo.id
            )
          )
          return workflow?.args?.orgId == orgId || found
        })

        // SORT THE WORKFLOW BY STARTED AT FROM RECENT TO OLD
        filterWorkflowByOrg.sort((prev, current) => {
          return (
            new Date(current.startedAt).getTime() -
            new Date(prev.startedAt).getTime()
          )
        })

        setWorkflows(filterWorkflowByOrg)
        if (workflows.length == 0) {
          resetVleiState()
        }
        setLoading(false)
      } catch (ex) {
        setLoading(false)
      }
    }
    ;(async () => {
      await fetchMyWorkflows()
    })()
  }, [workflowFilters])

  const handleOpenWorkflowDetail = (workflow: WorkflowInstanceDto): void => {
    if (
      workflow.workflowModelId?.toLocaleLowerCase() ==
      'create-le-vlei-prerequisites'
    ) {
      setWorkflowInstanceID(workflow.id)
      router.push(`/vlei/workflow/${workflow.id}`)
    } else if (
      workflow.workflowModelId?.toLocaleLowerCase() ==
      'create-oor-vlei-prerequisites'
    ) {
      router.push(`/oor/workflow/${workflow.id}`)
    } else if (
      workflow.workflowModelId?.toLocaleLowerCase() ==
      'create-ecr-vlei-prerequisites'
    ) {
      router.push(`/ecr/workflow/${workflow.id}`)
    }
  }

  return (
    <>
      <header className="flex items-center justify-between px-6 py-4 text-2xl font-semibold border-b border-grey-200 ">
        <span>
          <FormattedMessage id="sideBar.link.myWork" />
        </span>

        <div className="flex">
          <div className="mr-2">
            <WorkflowFilter
              filters={workflowFilters}
              setFilters={setWorkflowFilters}
            />
          </div>
          <DropdownComponent
            label={
              <>
                <div className="flex items-center gap-1 px-4 py-2 text-sm font-normal text-white rounded bg-royalBlue space">
                  <PlusIcon
                    color={tailwindConfig.theme.extend.colors.white}
                    weight="fill"
                    size={24}
                  />
                  <FormattedMessage id="cta.cnw" />
                </div>
              </>
            }
            menuItems={[
              {
                label: formatMessage({ id: 'workflow.issuance.le' }),
                onClick: () => {
                  if (workflowInstanceId) {
                    router.push(
                      `/vlei/introduction?workflowInstanceId=${workflowInstanceId}`
                    )
                  } else {
                    router.push('/vlei/introduction')
                  }
                }
              },
              {
                label: formatMessage({ id: 'workflow.issuance.oor' }),
                onClick: () => {
                  router.push('/oor/introduction')
                }
              },
              {
                label: formatMessage({ id: 'workflow.issuance.ecr' }),
                onClick: () => {
                  router.push('/ecr/introduction')
                }
              }
            ]}
            placement="bottom-end"
          />
        </div>
      </header>

      <main className="p-6">
        {loading && (
          <div className="flex items-center justify-center gap-2 py-6">
            <Spinner />
            <span>
              <FormattedMessage id="loading.workflow" />
            </span>
          </div>
        )}

        {!loading && workflows.length == 0 && (
          <p className="py-10 text-center">
            <FormattedMessage id="workflow.empty" />
          </p>
        )}

        {workflows.map((workflow, key) => {
          return (
            <AccordionTable
              className="my-3"
              key={key}
              columns={[
                {
                  dataKey: 'nodeName',
                  label: <FormattedMessage id="workflow.table.activityName" />
                },
                {
                  dataKey: 'assignee',
                  label: <FormattedMessage id="workflow.table.assignee" />
                },
                {
                  dataKey: 'nodeStatus',
                  label: <FormattedMessage id="workflow.table.activityStatus" />
                }
              ]}
              data={prepareWorkflowData(workflow)}
              topRightButton={
                <Button
                  dataTestId="dashboard--open"
                  label={<FormattedMessage id={'cta.open'} />}
                  mode="tertiary"
                  size="small"
                  onClick={() => handleOpenWorkflowDetail(workflow)}
                />
              }
              header={header(workflow)}
            />
          )
        })}
        <InvitationResponseToast></InvitationResponseToast>
      </main>
    </>
  )
}

export default MyWork
