import React from 'react'

interface IProps {
  rounded?: boolean
  children?: React.ReactNode
  mode?: 'success' | 'error' | 'warning' | 'info' | 'inProgress' | 'canceled'
}
export const Tag = ({ rounded = false, children, mode = 'info' }: IProps) => {
  const isRounded = rounded ? 'rounded-2xl' : 'rounded-md'
  const modeClass =
    mode === 'success'
      ? 'bg-secondaryGreen50 text-secondaryGreen900 border border-secondaryGreen100'
      : mode === 'warning'
      ? 'text-SecondaryOrange900 bg-SecondaryOrange50 border border-SecondaryOrange200'
      : mode === 'error'
      ? 'text-SecondaryRed900 bg-SecondaryRed50 border border-SecondaryRed200'
      : mode === 'inProgress'
      ? 'text-SecondaryBlue800 bg-SecondaryBlue50 border border-SecondaryBlue200'
      : mode === 'canceled'
      ? 'text-neutralsAdditional800 bg-grey100 border border-neutralsAdditional400'
      : 'text-black bg-grey100 border border-neutralsAdditional400'

  return (
    <span
      className={`flex items-center inline-flex px-2 py-1    ${isRounded} ${modeClass}`}
    >
      {children}
    </span>
  )
}
