import { useLarResponseStore, LAR_RESPONSE_STATUS } from '@/state/larResponse'
import { useEffect, useState } from 'react'
import Toast from '../base/Toast'
import { useIntl } from 'react-intl'
const InvitaionResponseToast = () => {
  // const storedResponse = useLarResponseStore((state) => state.response)
  const { formatMessage } = useIntl()
  const storedStatus = useLarResponseStore((state) => state.status)
  const storedResponseStatus = useLarResponseStore(
    (state) => state.responseStatus
  )
  const updateStatus = useLarResponseStore((state) => state.updateStatus)
  const [printMessage, setPrintMessage] = useState<string>('')

  useEffect(() => {
    return () => {
      updateStatus(false)
    }
  }, [])

  useEffect(() => {
    if (storedResponseStatus == LAR_RESPONSE_STATUS.SUCCESSFULLY_ACCEPTED)
      setPrintMessage(
        formatMessage({ id: 'lar.response.acknowledgement.accepted' })
      )
    else if (storedResponseStatus == LAR_RESPONSE_STATUS.SUCCESSFULLY_REJECTED)
      setPrintMessage(
        formatMessage({ id: 'lar.response.acknowledgement.rejected' })
      )
    else if (
      storedResponseStatus == LAR_RESPONSE_STATUS.GRANT_IS_ALREADY_ACCEPTED
    )
      setPrintMessage(
        formatMessage({ id: 'lar.response.acknowledgement.already_accepted' })
      )
    else if (
      storedResponseStatus == LAR_RESPONSE_STATUS.GRANT_IS_ALREADY_REJECTED
    )
      setPrintMessage(
        formatMessage({ id: 'lar.response.acknowledgement.already_rejected' })
      )
    else
      setPrintMessage(
        formatMessage({ id: 'lar.response.acknowledgement.error' })
      )
  }, [storedResponseStatus])

  return (
    <>
      {storedStatus && (
        <Toast
          mode={
            storedResponseStatus == LAR_RESPONSE_STATUS.ERROR
              ? 'ERROR'
              : 'SUCCESS'
          }
          placement="TOP-RIGHT"
          onClose={() => updateStatus(false)}
        >
          {printMessage}
        </Toast>
      )}
    </>
  )
}

export default InvitaionResponseToast
