import React from 'react'

interface ProgressBarProps {
  mode?: 'primary' | 'success' | 'danger'
  progress?: number
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large'

  disabled?: boolean
  showPercentage?: boolean
}

const getSizeClass = (size: string) => {
  switch (size) {
    case 'small':
      return 'h-1'
    case 'medium':
      return 'h-3'
    case 'large':
      return 'h-5'
    default:
      return 'h-3'
  }
}

const getTextSizeClass = (size: string) => {
  switch (size) {
    case 'small':
      return 'text-[0px]'
    case 'medium':
      return 'text-[8px]'
    case 'large':
      return 'text-[12px]'
    default:
      return 'text-[6px]'
  }
}

const getProgress = (progress: number) => {
  if (progress < 0) {
    return 0
  } else if (progress > 100) {
    return 100
  } else {
    return progress
  }
}

/**
 * Primary UI component for user interaction
 */
export const ProgressBar = ({
  mode = 'primary',
  size = 'medium',
  disabled = false,
  progress = 10,
  showPercentage = false
}: ProgressBarProps) => {
  const modeColor = disabled
    ? 'bg-gray-300 border-gray-300 text-white'
    : mode === 'primary'
    ? 'bg-royalBlue border border-royalBlue '
    : mode === 'success'
    ? 'bg-successGreen border'
    : mode === 'danger'
    ? 'bg-SecondaryRed900 px-0'
    : 'bg-red-100 border border-red-100 text-red-600'

  const sizeClass = getSizeClass(size)
  const textClass = getTextSizeClass(size)

  return (
    <span
      role="progressbar"
      aria-labelledby="ProgressLabel"
      aria-valuenow={getProgress(progress)}
      className="block rounded-full bg-gray-200 "
    >
      <span
        style={{ width: `${getProgress(progress)}%` }}
        className={[
          modeColor,
          sizeClass,
          textClass,
          'block rounded-full bg-gray-200 text-center '
        ].join(' ')}
      >
        {showPercentage && (
          <span className="font-bold text-white">
            {' '}
            {getProgress(progress)}%{' '}
          </span>
        )}
      </span>
    </span>
  )
}
