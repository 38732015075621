import TaskStatus from '@/enums/taskStatus'

export class TaskActor {
  assignedTaskId: string
  workflowInstanceId: string
  userId: string
  userName: string
  taskType: string
  status: TaskStatus

  constructor(
    assignedTaskId: string,
    workflowInstanceId: string,
    userId: string,
    userName: string,
    taskType: string,
    status: TaskStatus
  ) {
    this.assignedTaskId = assignedTaskId
    this.workflowInstanceId = workflowInstanceId
    this.userId = userId
    this.userName = userName
    this.taskType = taskType
    this.status = status
  }

  isComplete(): boolean {
    return this.status === TaskStatus.COMPLETE
  }
}
