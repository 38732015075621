import { Dropdown, Checkbox, Radio } from 'flowbite-react'
import React, { FC, SVGProps } from 'react'

export interface IMenuItems {
  label: React.ReactNode
  icon?: FC<SVGProps<SVGSVGElement>>
  onClick?: () => void
  isDivider?: boolean
  isSelected?: boolean
  [key: string]: any
}

interface IProps {
  id?: string
  className?: string
  label: React.ReactNode
  header?: React.ReactNode
  menuItems?: IMenuItems[]
  size?: 'xs' | 'sm' | 'lg' | 'xl'
  multiSelect?: boolean
  placement?:
    | 'auto'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left'
    | 'left-start'
    | 'left-end'
}

const SelectableDropdownComponent = ({
  id,
  className = 'mt-[-40px] rounded-md',
  label,
  header,
  menuItems = [],
  size = 'sm',
  multiSelect = false,
  placement = 'auto'
}: IProps) => {
  return (
    <>
      <style>
        {`
            [data-testid=flowbite-tooltip-target] button {
              border-radius: 0.50rem;
            }
            [data-testid=flowbite-tooltip-target] button:focus {
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
            }
          `}
      </style>

      <Dropdown
        id={id ? `selectable-dropdown-${id}` : 'selectable-dropdown'}
        label={label}
        size={size}
        placement={placement}
        color="gray"
        className={className}
      >
        {header && (
          <Dropdown.Header className="!py-1 text-neutralsAdditional800">
            {header}
          </Dropdown.Header>
        )}
        {menuItems.map((menuItem, key) => {
          return (
            <React.Fragment key={key}>
              <Dropdown.Item
                icon={menuItem?.icon}
                onClick={() => menuItem.onClick && menuItem.onClick()}
                className={`w-full gap-1 !py-1 font-medium  text-neutralsAdditional800 `}
              >
                {multiSelect ? (
                  <Checkbox
                    checked={menuItem.isSelected}
                    onChange={menuItem.onClick}
                  />
                ) : (
                  <Radio
                    checked={menuItem.isSelected}
                    onChange={menuItem.onClick}
                  />
                )}
                {menuItem.label}
              </Dropdown.Item>
              {menuItems?.length !== key + 1 && <Dropdown.Divider />}
            </React.Fragment>
          )
        })}
      </Dropdown>
    </>
  )
}
export default SelectableDropdownComponent
