import { WorkflowInstanceStatus, WorkflowType } from '@/types/workflow'
import { Task } from './task'
import { TaskActor } from './task-actor'
import TaskStatus from '@/enums/taskStatus'
import OorWorkflowTask from '@/types/oor-workflow-task'
import type {
  TaskTypeResponseDto,
  WorkflowInstanceDto
} from '../../../api/origin-workflow-svc'

export class Workflow {
  protected data: WorkflowInstanceDto
  protected tasks: Task[]
  private type: WorkflowType

  constructor(data: WorkflowInstanceDto, type: WorkflowType) {
    this.data = data
    this.type = type
    this.tasks = this.formatTaskDetails()
  }

  public get rawData(): WorkflowInstanceDto {
    return this.data
  }

  public getWorkflowType(): WorkflowType {
    return this.type
  }

  public getTasks(): Task[] {
    return this.tasks
  }

  public getId(): string {
    return this.data.id
  }

  public getName(): string {
    return this.data.name
  }

  public getOrgId(): string {
    return this.data.orgId
  }

  public getOrgName(): string {
    //TODO: get org name from backend
    return this.data.orgId
  }

  public getStatus(): WorkflowInstanceStatus {
    return this.data.status as WorkflowInstanceStatus
  }

  public getWorkflowModelId(): string {
    return this.data.workflowModelId
  }

  public getRequesterId(): string {
    return this.data.requesterId
  }

  public getRequesterName(): string {
    return this.data.requesterName
  }

  public getStartedAt(): string {
    return this.data.startedAt
  }

  public getTaskTypes(): TaskTypeResponseDto[] {
    return this.data.taskTypes
  }

  public isStarted(): boolean {
    return this.data.startedAt !== null
  }

  private formatTaskDetails(): Task[] {
    const tasks = this.data.taskTypes.map((taskType) => {
      const actors: TaskActor[] = taskType.taskActors.map(
        (actor) =>
          new TaskActor(
            actor.workflowInstanceAssignedTaskId,
            actor.workflowInstanceId,
            actor.userId,
            actor.userName,
            actor.taskType,
            // todo ask Arsh / BE to update swagger by providing an enum
            // @ts-ignore
            actor.status
          )
      )

      return new Task(taskType.taskType, actors)
    })

    return tasks
  }

  /**
   * Find task by task name
   *
   * If taskType is enum, then use like:
   *
   * findTaskByType(OorWorkflowTask[OorWorkflowTask.INTRODUCTION])
   */
  public findTaskByType(taskType: string): Task | undefined {
    return this.tasks.find((task) => task.taskType === taskType)
  }
  /**
   * Finds a task actor based on the given task type and user ID.
   * If there are no tasks or no actors in the matching task, it returns undefined.
   *
   * @param {string} taskType - The type of the task.
   * @param {string} userId - The ID of the user.
   * @returns {TaskActor | undefined} - Returns the task actor if found, otherwise undefined.
   */
  public findActorByTaskTypeAndUserId(
    taskType: string,
    userId: string
  ): TaskActor | undefined {
    for (const task of this.tasks) {
      if (task.taskType === taskType) {
        return task.actors.find((actor) => actor.userId === userId)
      }
    }
    return undefined
  }

  /**
   * Checks if a user is assigned a task based on task type.
   *
   * @param {string} taskType - The type of the task to search in.
   * @param {string} userId - The ID of the user to search for.
   * @returns {boolean} - Returns true if the user is assigned to an actor in the specified task type, otherwise false.
   */
  public isUserAssignedToTaskType(taskType: string, userId: string): boolean {
    for (const task of this.tasks) {
      if (task.taskType === taskType) {
        const isUserAssigned = task.actors.some(
          (actor) => actor.userId === userId
        )
        if (isUserAssigned) {
          return true
        }
      }
    }
    return false
  }

  /**
   * Calculates the completion percentage of all tasks within the workflow.
   *
   * This method iterates through each task and its actors, counting the number of completed tasks.
   * If a task has no actors yet, it assumes a default 1 task actor for the calculation.
   *
   * @returns An object containing totalTasks, completedTasks, and percentage.
   */
  public getTaskCompletionStats(): {
    totalTasks: number
    completedTasks: number
    percentage: number
  } {
    let completedTaskCount = 0
    let totalTaskCount = 0

    this.tasks.forEach((task) => {
      if (task.actors.length === 0) {
        // Assume a default task actor
        if (task.taskType !== OorWorkflowTask.PROVIDE_ADDITIONAL_DOCUMENTS) {
          totalTaskCount++
        }
      } else {
        task.actors.forEach((actor) => {
          if (
            actor.status === TaskStatus.COMPLETE ||
            actor.status === TaskStatus.REJECTED
          ) {
            completedTaskCount++
          }
          totalTaskCount++
        })
      }
    })
    const percentage =
      totalTaskCount > 0
        ? Math.round((completedTaskCount / totalTaskCount) * 100)
        : 0

    return {
      totalTasks: totalTaskCount,
      completedTasks: completedTaskCount,
      percentage
    }
  }

  public toJson(): string {
    const jsonObject = {
      rawData: this.data,
      tasks: this.tasks.map((task) => ({
        taskType: task.taskType,
        actors: task.actors.map((actor) => ({
          assignedTaskId: actor.assignedTaskId,
          workflowInstanceId: actor.workflowInstanceId,
          userId: actor.userId,
          userName: actor.userName,
          taskType: actor.taskType,
          status: actor.status
        }))
      }))
    }

    return JSON.stringify(jsonObject, null, 2)
  }
}
