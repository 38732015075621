import Badges from '@/components/base/Badges'
import SelectableDropdownComponent from '@/components/base/SelectableDropdown'

export type WorkflowFilter = {
  label: string
  value: string
  isSelected: boolean
}

export type WorkflowFilterProps = {
  filters: Array<WorkflowFilter>
  setFilters: (filters: Array<WorkflowFilter>) => void
}

export const WorkflowFilter: React.FC<WorkflowFilterProps> = ({
  filters,
  setFilters
}) => {
  const onFilterChange = (filterValue: string) => {
    const newFilters = filters.map((f) => {
      if (f.value === filterValue) {
        return {
          ...f,
          isSelected: !f.isSelected
        }
      }
      return f
    })
    setFilters(newFilters)
  }

  const selectedCount = filters.reduce((acc, filter) => {
    return filter.isSelected ? acc + 1 : acc
  }, 0)

  return (
    <div>
      <SelectableDropdownComponent
        header="Filters"
        placement="bottom"
        label={
          <>
            <span className="text-neutralsAdditional800 mr-1">Status:</span>
            <span>
              <Badges
                label={selectedCount}
                color="blue"
                type="pill"
                size="small"
              />
            </span>
          </>
        }
        multiSelect={true}
        menuItems={filters.map((filter) => ({
          ...filter,
          onClick: () => onFilterChange(filter.value)
        }))}
      />
    </div>
  )
}
