import DocVerifiedStatus from '@/enums/docVerifiedStatus'
import TaskStatus from '@/enums/taskStatus'
import TaskType from '@/enums/taskType'
import {
  commonWorkflowService,
  ecrPrerequisitesService,
  oorPrerequisitesService,
  orgResearchService,
  orgService,
  orgTypeService,
  searchService,
  serviceProviderInternalApiService,
  utilService,
  vleiPrerequisitesService,
  workflowInstanceService
} from '@/utils/apis'
import {
  DarProposalRequest,
  type DarProposalRequestDto,
  DarReplacementRequest,
  FindAllForRequesterRequest,
  FindAllUsersInOrgAndGrantRequest,
  FindAvailableQarsRequest,
  FindScheduledMeetingRequest,
  IdVerificationRequest,
  type IdVerificationRequestDto,
  LarProposalRequest,
  type LarProposalRequestDto,
  LarRemovalRequest,
  type LarRemovalRequestDto,
  OrgFillingDetailRequest,
  type OrgFillingDetailRequestDto,
  QarAcceptsDarRequest,
  type QarAcceptsDarRequestDto,
  QarRejectsDarRequest,
  type QarRejectsDarRequestDto,
  Start1Request,
  type VLEIPrerequisitesRequestDto
} from '@/api/origin-workflow-svc'
export interface ITaskActor {
  workflowInstanceAssignedTaskId: string
  userId: string
  userName: string
  workflowInstanceId: string
  taskType: TaskType
  symbolicTextId: string | null
  status: TaskStatus | DocVerifiedStatus
  callbackUrl: string
}
export interface ITaskType {
  workflowModelId: string
  taskType: TaskType
  symbolicTextId: string
  taskActors: ITaskActor[]
}

export interface IProposedUserResponse {
  userId: string
  userName: string
  orgId: string
  orgName?: string
  emails: string[]
  phoneNumbers?: {
    userId: string
    value: string
    countryCode: string
  }[]
  grant: string
  isAccepted: boolean
  approvedByUser: string
  proposedByUser: string
}

interface Attendee {
  userId: string
  name: string
}

interface CeremonyTime {
  start: string
  end: string
}

export interface CeremonyResponse {
  attendees: Attendee[]
  ceremonyTime: CeremonyTime
  title: string
  meetingUrl: string
}

export interface MeetingWorkflowData {
  workflowInstanceId: string
  orgId: string
  lei: string
}

export async function getWorkflows(status?: string) {
  return await workflowInstanceService.findAllForRequester({
    withTasks: true,
    status
  } as FindAllForRequesterRequest)
}

export async function startWorkflow(
  vLEIPrerequisitesRequestDto: VLEIPrerequisitesRequestDto
) {
  return await vleiPrerequisitesService.start1({
    vLEIPrerequisitesRequestDto
  } as Start1Request)
}

export async function getOrganizationTypes() {
  return orgTypeService.findAllOrgTypes()
}

export async function getOrganizationDetails(orgId: string) {
  return await orgService.getOrg({ orgId })
}

export async function getOrganizationResearchDetails(orgId: string) {
  return await orgResearchService.findByOrgId2({ orgId })
}

export async function searchOrganizationByLEI(lei: string) {
  return searchService.searchByLei({ lei })
}

//returned org_research_id
export async function boundOrgResearchWithOrgId(
  orgResearch: string,
  org: string
) {
  return await orgResearchService.boundOrgResearch({ org, orgResearch })
}

export async function updateOrgDetails(
  orgFillingDetailRequestDto: OrgFillingDetailRequestDto
) {
  return await vleiPrerequisitesService.orgFillingDetail({
    orgFillingDetailRequestDto
  } as OrgFillingDetailRequest)
}

export async function proposeDARCoordinator(
  darProposalRequestDto: DarProposalRequestDto
) {
  return await vleiPrerequisitesService.darProposal({
    darProposalRequestDto
  } as DarProposalRequest)
}
export async function proposeLARRepresentative(
  larProposalRequestDto: LarProposalRequestDto
) {
  return vleiPrerequisitesService.larProposal({
    larProposalRequestDto
  } as LarProposalRequest)
}

// todo ask BE to add enum instead of string for DAR
export async function getProposedDARDetailsByOrgID(org: string) {
  return utilService.findAllUsersInOrgAndGrant({
    org,
    role: 'DAR'
  } as FindAllUsersInOrgAndGrantRequest)
}

export async function getProposedLARDetailsByOrgID(org: string) {
  return utilService.findAllUsersInOrgAndGrant({
    org,
    role: 'lar'
  } as FindAllUsersInOrgAndGrantRequest)
}

export async function startIdentityVerification(
  idVerificationRequestDto: IdVerificationRequestDto
) {
  const mocked = !!JSON.parse(localStorage.getItem('mockIdVerification'))
  return commonWorkflowService.idVerification({
    idVerificationRequestDto,
    mocked
  } as IdVerificationRequest)
}

export async function getCeremonyTime(workflow: string) {
  return await commonWorkflowService.findScheduledMeeting({
    workflow
  } as FindScheduledMeetingRequest)
}

export async function getAvailableQARs(payload: FindAvailableQarsRequest) {
  return await commonWorkflowService.findAvailableQars(payload)
}

export async function getWorkflowIdByMeetingId(meeting: string) {
  return await commonWorkflowService.findMeetingOrgParameters({ meeting })
}

export async function approveProposedCoordinator(
  qarAcceptsDarRequestDto: QarAcceptsDarRequestDto
) {
  return await vleiPrerequisitesService.qarAcceptsDar({
    qarAcceptsDarRequestDto
  } as QarAcceptsDarRequest)
}

export async function rejectProposedCoordinator(
  qarRejectsDarRequestDto: QarRejectsDarRequestDto
) {
  return vleiPrerequisitesService.qarRejectsDar({
    qarRejectsDarRequestDto
  } as QarRejectsDarRequest)
}

export async function removeProposedLar(
  larRemovalRequestDto: LarRemovalRequestDto
) {
  return vleiPrerequisitesService.larRemoval({
    larRemovalRequestDto
  } as LarRemovalRequest)
}

export async function completeWorkflow(workflowInstanceId: string) {
  return workflowInstanceService.completeInstance({ workflowInstanceId })
}

export const sendCredentialRevocationNotification = async (
  aid: string,
  cred: string
) => {
  return await utilService.credentialRevocationNotificationRaw({
    credentialRevocationNotificationDto: { aid, cred }
  })
}

export const getOrgDetailsByWorkflowId = async (workflowInstanceId: string) => {
  return await utilService.orgDetail({
    workflow: workflowInstanceId
  })
}

export const QARApproveOrganization = async (workflowInstanceId: string) => {
  return await vleiPrerequisitesService.qarApprovesOrgParameters({
    qarAcceptsOrgRequestDto: { workflowInstanceId }
  })
}

export const qarVerifiesOrg = async (workflowInstanceId: string) => {
  return await oorPrerequisitesService.qarVerifiesOrg({
    qarAcceptsOrgRequestDto: { workflowInstanceId }
  })
}

export const DARReplaceMent = async (requestBody: DarReplacementRequest) => {
  return await vleiPrerequisitesService.darReplacement(requestBody)
}

export const getLarsFromWorkflowId = async (workflowId: string) => {
  return await utilService.findOrgLars({ workflow: workflowId })
}

export const cancelLeCeremony = async (workflowInstanceId: string) => {
  return await vleiPrerequisitesService.cancelCeremony1({
    cancelCeremonyRequestDto: { workflowInstanceId }
  })
}

export const cancelOorCeremony = async (workflowInstanceId: string) => {
  return await oorPrerequisitesService.cancelCeremony({
    cancelCeremonyRequestDto: { workflowInstanceId }
  })
}

export const cancelEcrCeremony = async (workflowInstanceId: string) => {
  return await ecrPrerequisitesService.cancelCeremony2({
    cancelCeremonyRequestDto: { workflowInstanceId }
  })
}

export const getWorkflowByWorkflowInstanceId = (
  workflowInstanceId: string,
  withTasks: boolean = true
) => {
  return workflowInstanceService.findOne({ workflowInstanceId, withTasks })
}
