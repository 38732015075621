import React from 'react'
import TaskStatus from '@/enums/taskStatus'
import { Tag } from '@/components/base/Tag'
import { FormattedMessage } from 'react-intl'

interface IProps {
  status: TaskStatus
}

export const TaskStatusTag = ({ status }: IProps) => {
  if (status === TaskStatus.COMPLETE)
    return (
      <Tag mode="success">
        <FormattedMessage id="status.completed" />
      </Tag>
    )
  else if (status === TaskStatus.TERMINATED)
    return (
      <Tag mode="error">
        <FormattedMessage id="status.terminated" />
      </Tag>
    )
  else if (status === TaskStatus.ASSIGNED)
    return (
      <Tag mode="info">
        <FormattedMessage id="status.todo" />
      </Tag>
    )
  else if (status === TaskStatus.IN_PROGRESS)
    return (
      <Tag mode="inProgress">
        <FormattedMessage id="status.inProgress" />
      </Tag>
    )
  else if (status === TaskStatus.NOT_ASSIGNED)
    return (
      <Tag mode="info">
        <FormattedMessage id="status.notAssigned" />
      </Tag>
    )
  else if (status === TaskStatus.CANCELED)
    return (
      <Tag mode="canceled">
        <FormattedMessage id="workflow.done" />
      </Tag>
    )
  return null
}
