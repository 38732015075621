import React, { ReactNode, useState } from 'react'
import Arrow from '../icons/Arrow'
import tailwindConfig from 'tailwind.config'
interface IColumns {
  label: ReactNode
  dataKey: string
}

interface dataObject {
  [key: string]: any
}

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  header: ReactNode
  columns: IColumns[]
  data: dataObject[]
  topRightButton?: ReactNode
  expandAtBegin?: boolean
}

const AccordionTable = ({
  header,
  columns,
  data = [],
  topRightButton,
  expandAtBegin = false,
  ...props
}: IProps) => {
  const [expanded, setExpanded] = useState(expandAtBegin)

  return (
    <div {...props}>
      <div className={`bg-white border border-grey200 rounded-md shadow`}>
        <div className="flex items-center justify-between gap-3 p-4">
          <div className="w-full">{header}</div>
          {topRightButton ? (
            <>{topRightButton}</>
          ) : (
            <span
              className="px-3 py-4 border rounded-md cursor-pointer border-grey200 bg-grey"
              onClick={() => setExpanded(!expanded)}
            >
              <Arrow
                direction={expanded ? 'UP' : 'DOWN'}
                color={tailwindConfig.theme.extend.colors.neutralsAdditional800}
              />
            </span>
          )}
        </div>

        {!expanded && (
          <div className="relative">
            <div className="m-3 p-3 border border-grey200 bg-grey rounded relative z-[5] mb-8">
              <table width="100%">
                <tbody>
                  <tr>
                    {columns.map((column, key) => {
                      return <td key={key}>{data?.[0]?.[column.dataKey]}</td>
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="w-[calc(100%-2rem)] left-1/2 -translate-x-1/2 p-3 border border-grey200 bg-grey rounded absolute z-[3] -bottom-2"></div>
            <div className="w-[calc(100%-3rem)] left-1/2 -translate-x-1/2 p-3 border border-grey200 bg-grey rounded absolute z-[2] -bottom-4"></div>
          </div>
        )}

        {expanded && (
          <div className="flex">
            <table width={'100%'}>
              <thead className="p-2 text-sm font-normal border border-l-0 border-r-0 border-grey200 bg-grey text-neutralsAdditional800">
                <tr>
                  {columns.map((column, key) => (
                    <td key={key} className="p-3">
                      {column.label}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => {
                  return (
                    <tr
                      key={key}
                      className="border-b hover:bg-grey border-b-grey200 last:border-none"
                    >
                      {columns.map((column, key) => {
                        return (
                          <td key={key} className="p-3">
                            {item[column.dataKey]}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default AccordionTable
