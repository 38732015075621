import TaskStatus from '@/enums/taskStatus'
import { TaskActor } from './task-actor'

export class Task {
  private _taskType: string
  private _actors: TaskActor[]

  constructor(taskType: string, actors: TaskActor[]) {
    this._taskType = taskType
    this._actors = actors
  }

  get taskType(): string {
    return this._taskType
  }

  get actors(): TaskActor[] {
    return this._actors
  }

  hasAssignedActors(): boolean {
    if (this.actors.length === 0) {
      return false
    } else return true
  }

  /**
   * Find a task actor by user ID i.e. find task assignment record by user ID
   *
   * @param {string} userId - The ID of the user.
   * @returns {TaskActor | undefined} - Returns the task actor if found, otherwise undefined.
   */
  findActorByUserId(userId: string): TaskActor | undefined {
    return this._actors.find((actor) => actor.userId === userId)
  }

  /**
   * Checks if a user is assigned as an actor in this task.
   *
   * @param {string} userId - The ID of the user to search for.
   * @returns {boolean} - Returns true if the user is assigned as an actor in this task, otherwise false.
   */
  public isUserAssignedToTask(userId: string): boolean {
    return this.actors.some((actor) => actor.userId === userId)
  }

  /**
   * Checks if all actors in the task have completed their respective actions.
   *
   * @returns {boolean} Returns true if all task actors have status of 'COMPLETE',
   *                    otherwise returns false. If there are no actors assigned, the task is considered
   *                    as not completed
   */
  public isTaskCompleted(): boolean {
    if (this.actors.length === 0) {
      return false
    }
    return this.actors.every((actor) => actor.status === TaskStatus.COMPLETE)
  }

  getActorNamesAsCommaSeparated(): string {
    if (this._actors.length === 0) {
      return ''
    }
    return this.actors.map((actor) => actor.userName).join(', ')
  }

  /**
   * Calculates the overall status of the task based on the statuses of its actors.
   * Rules:
   * - If there are no task actors, the status is NOT_STARTED.
   * - If any task actor is IN_PROGRESS, the status is IN_PROGRESS.
   * - If all task actors are ASSIGNED, the status is ASSIGNED.
   * - If all task actors are COMPLETE, the status is COMPLETE.
   * - Otherwise, defaults to IN_PROGRESS.
   *
   * @returns {TaskStatus} - The overall status of the task.
   */
  public calculateTaskStatus(): TaskStatus {
    if (this._actors.length === 0) {
      return TaskStatus.NOT_STARTED
    }

    if (this._actors.some((actor) => actor.status === TaskStatus.IN_PROGRESS)) {
      return TaskStatus.IN_PROGRESS
    }

    const allAssigned = this._actors.every(
      (actor) => actor.status === TaskStatus.ASSIGNED
    )
    if (allAssigned) {
      return TaskStatus.ASSIGNED
    }

    const allComplete = this._actors.every(
      (actor) => actor.status === TaskStatus.COMPLETE
    )
    if (allComplete) {
      return TaskStatus.COMPLETE
    }

    return TaskStatus.IN_PROGRESS
  }
}
