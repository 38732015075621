import { create } from 'zustand'

export enum LAR_RESPONSE_STATUS {
  SUCCESSFULLY_ACCEPTED = 'SUCCESSFULLY_ACCEPTED',
  SUCCESSFULLY_REJECTED = 'SUCCESSFULLY_REJECTED',
  GRANT_IS_ALREADY_ACCEPTED = 'GRANT_IS_ALREADY_ACCEPTED',
  GRANT_IS_ALREADY_REJECTED = 'GRANT_IS_ALREADY_REJECTED',
  ERROR = 'ERROR'
}

export interface ILarResponseStore {
  response: string
  status: boolean
  responseStatus: string
  errorMessage: string
  updateResponse: (showToast: string) => void
  updateStatus: (newStatus: boolean) => void
  updateResponseStatus: (newStatus: string) => void
}

export const useLarResponseStore = create<ILarResponseStore>((set) => ({
  response: '',
  status: false,
  responseStatus: undefined,
  errorMessage: '',
  updateResponse: (newResponse) => set({ response: newResponse }),
  updateStatus: (newStatus) => set({ status: newStatus }),
  updateResponseStatus: (newStatus) => set({ responseStatus: newStatus })
}))
